import styles from '../styles/HeaderShortcuts.module.scss';
import {useContext, useState} from "react";
import {StoreContext} from "../../../stores/StoreLoader";
import FontAwesome from "../../utilities/FontAwesome";
import {conditionType, currentViewType} from "../../modals/AdminModal/AdminModalTypes";
import NavigationModel from "../../../utils/NavigationModel";
import {runInAction} from "mobx";
import WatsonApi from "../../../backends/WatsonApi";
import {observer} from "mobx-react";
import NotificationManager from "../../notifications/NotificationManager";
import classNames from "classnames";
import {addLeadingZeros} from "../../../utils/DataUtilities";
import {Shortcut} from "./Shortcut";
import dynamic from "next/dynamic";

const DraggableShortcut = dynamic(() => import("./DraggableShortcut"));

export const HeaderShortcuts = observer((props) => {
    const {userStore, modalStore, organizationStore} = useContext(StoreContext);

    const [isDragging, setIsDragging] = useState(false);
    const [reorder, setReorder] = useState(false);

    const containerClassName = classNames({
        [styles.container]: true,
    });

    const reorderClassName = classNames({
        [styles.reorder]: reorder,
    });

    const navigationCreator = async (navigation) => {
        try {
            let index = 0;
            for (let i=0; i < organizationStore.currentOrganization.shortcuts.length; i++) {
                if (organizationStore.currentOrganization.shortcuts[i].parent_id !== organizationStore.currentOrganization.id) {
                    index = i;
                    break;
                }
                index = i + 1;
            }
            const path = organizationStore.currentOrganization.path.split(".")
            const newPath = `${path[path.length-1]}.${addLeadingZeros(index + 1)}`
            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_navigations_create({
                organization_pk: organizationStore.currentOrganization.id,
                data: {
                    title: navigation.title,
                    type: 0,
                    external_url: navigation.external_url,
                    icon: navigation.icon,
                    class_field: navigation.class_field,
                    description: navigation.description,
                    path: newPath,
                    enabled: navigation.enabled,
                    parent_id: navigation.parent_id,
                    organization_id: navigation.organization_id,
                },
            })
            runInAction(() => {
                organizationStore.currentOrganization.shortcuts.splice(index, 0, JSON.parse(response.data));
            })
            return JSON.parse(response.data);
        } catch (e) {
            NotificationManager.error("Something went wrong! Please try again.")
        }
    }

    const navigationUpdater = async (navigation) => {
        try {
            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_navigations_update({
                organization_pk: organizationStore.currentOrganization.id,
                id: navigation.id,
                data: {
                    id: navigation.id,
                    title: navigation.title,
                    type: 0,
                    external_url: navigation.external_url,
                    icon: navigation.icon,
                    class_field: navigation.class_field,
                    description: navigation.description,
                    path: navigation.path,
                    enabled: navigation.enabled,
                    parent_id: navigation.parent_id,
                    organization_id: navigation.organization_id,
                },
            })
            runInAction(() => {
                organizationStore.currentOrganization.shortcuts = organizationStore.currentOrganization.shortcuts.map(link => {
                    if (link.id === navigation.id) {
                        return JSON.parse(response.data);
                    }
                    return link;
                })
            })
        } catch (e) {
            NotificationManager.error("Something went wrong! Please try again.")
        }
    }

    const deleteNavigation = async (id) => {
        if (confirm("Are you sure you want to do that?")) {
            try {
                const client = await WatsonApi();
                await client.apis.organizations.organizations_navigations_delete({
                    organization_pk: organizationStore.currentOrganization.id,
                    id,
                });
                runInAction(() => {
                    organizationStore.currentOrganization.shortcuts = organizationStore.currentOrganization.shortcuts.filter(l => l.id !== id);
                })
            } catch (e) {
                NotificationManager.error("Something went wrong! Please try again.")
            }
        }
    }

    const updateNavigation = (navigation) => {
        modalStore.addModal({
            type: "admin",
            condition: conditionType.EDITING,
            nodeInfo: navigation,
            navigation: [],
            parent: String(organizationStore.currentOrganization.id),
            navigationUpdater,
        })
    }

    function onHoverCallback(list) {
        runInAction(() => {
            organizationStore.currentOrganization.shortcuts = list;
        })
    }

    async function onDrop() {
        const path = organizationStore.currentOrganization.path.split(".")
        for (let i=0; i<organizationStore.currentOrganization.shortcuts.length; i++) {
            if (organizationStore.currentOrganization.shortcuts[i].parent_id === organizationStore.currentOrganization.id) {
                const newPath = `${path[path.length-1]}.${addLeadingZeros(i + 1)}`
                organizationStore.currentOrganization.shortcuts[i].path = newPath;
            }
        }
        try {
            const client = await WatsonApi();
            await client.apis.organizations.organizations_navigations_bulk_update({
                organization_pk: organizationStore.currentOrganization.id,
                data: organizationStore.currentOrganization.shortcuts.filter((shortcut) => shortcut.parent_id === organizationStore.currentOrganization.id),
            });
            NotificationManager.success("Shortcuts successfully updated.");
        } catch (e) {
            NotificationManager.error("Oops! Something went wrong. Please try again.");
        }
    }

    return <div className={containerClassName}>
        <div>
            {userStore.isEditor &&
                <>
                    <button title={"Add a Shortcut"} onClick={() => {
                        modalStore.addModal({
                            type: "admin",
                            condition: conditionType.ADD_TOPLINK,
                            nodeInfo: new NavigationModel(),
                            navigation: [],
                            parent: String(organizationStore.currentOrganization.id,),
                            initialView:currentViewType.shortcut,
                            navigationCreator,
                        })
                    }}>
                        <FontAwesome name={"fa-plus"} fixedWidth prefix={"fas"} ariaHidden />
                    </button>
                    <button title={"Reorder Shortcuts"} onClick={() => {
                        setReorder(v => !v)
                    }}>
                        <FontAwesome prefix={"fas"} fixedWidth name={"fa-arrows-up-down"} ariaHidden className={reorderClassName}/>
                    </button>
                </>}
            {organizationStore.currentOrganization.shortcuts?.length > 0 && <ul>
                {organizationStore.currentOrganization.shortcuts.map((link, index) => {
                    if (userStore.isEditor && link.parent_id === organizationStore.currentOrganization.id) {
                        return <DraggableShortcut key={link.id}
                                                  shortcut={link}
                                                  subNavigation={organizationStore.currentOrganization.shortcuts}
                                                  onHoverCallback={onHoverCallback}
                                                  index={index}
                                                  isDragging={isDragging}
                                                  setIsDragging={setIsDragging}
                                                  reorder={reorder}
                                                  updateNavigation={updateNavigation}
                                                  deleteNavigation={deleteNavigation}
                                                  className={`${styles.shortcutContainer} ${styles.icon}`}
                                                  onDrop={onDrop}
                                                  canEdit={userStore.isEditor && (link.parent_id === organizationStore.currentOrganization.id)}/>
                    } else {
                        return <Shortcut key={link.id}
                                         shortcut={link}
                                         updateNavigation={updateNavigation}
                                         deleteNavigation={deleteNavigation}
                                         className={`${styles.shortcutContainer} ${styles.icon}`}
                                         canEdit={userStore.isEditor && (link.parent_id === organizationStore.currentOrganization.id)}/>
                    }
                })}
            </ul>}
        </div>
    </div>
})
